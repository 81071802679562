.faq {
  section {
    padding-bottom: 60px;
  }

  .qa-list {
    .list-item {
      display: block;
      margin-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $orange;
      cursor: pointer;

      ul {
        margin: 15px 0;
        padding-left: 15px;
        li {
          margin: 5px;
          list-style-type: disc;
        }
      }

      .question {
        font-weight: 700;
      }

      .answer {
        display: none;
      }

      &.active {
        .question {
          margin-bottom: 10px;
        }

        .answer {
          display: block
        }
      }
    }
  }
}