.stats {

  .stats-summary {
    .stats-header {
      display: flex;
      justify-content: space-between;
      font-weight: 700;

      div {
        line-height: 23px;
        margin: 20px 0;
      }
      .score {
        color: $orange;
      }
    }

    .stats-pane {
      padding: 10px 0 0;
      border-radius: 12px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
      background-image: linear-gradient(to bottom, #fff, #fafcff);
      overflow: hidden;
    }

    .stats-pane-header {
      padding: 0 25px;



      .drop-down {
        border-radius: 20px;
        width: 146px;
        position: relative;
        color: #fff;
        background:$orange;
        height: 22px;
        // padding: 0 10px;
        padding-left: 20px;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
      .drop-down-active {
        position: relative;
        width: 100%;

        .arrow {
          position: absolute;
          right:10%;
          top:50%;
          transform: translate(-50%,-50%) rotate(180deg);
          width: 10px;
          height: 5px;

          display: flex;
          justify-content: center;
          align-items: center;
          
        }

        .arrow-active {
          transform: translate(-50%,-50%) rotate(0deg);
        }
     
       
      }

     

      .drop-down-list {
        position: absolute;
        top:25px;
        left: 0;
        background: #fff;
        width: 146px;
        border-radius: 12px;
        border:1px solid $orange;
        display: none;

        &--active {
          display: block;
        }
      
      }
      .drop-down-list__item {
        // margin: 0 10px;
        border-radius: 20px;
        color: $orange;
        padding: 0 10px;
        height: 20px;
        display: flex;
        align-items: center;
        position: relative;
        width: 130px;
        margin: 1px auto;
      }
      .drop-down-list__item:not(:first-child) {
        margin-top: 4px;
      }

      .drop-down-list__item:not(:last-child)::before {
        content: '';
        position: absolute;
        left: 0;
        bottom:-3px;
        right: 0;
        display: block;
        background: $orange;
        width: 100%;
        height: 1px;
      }
      .drop-down-list__item:hover {
        background: $orange;
        color: #fff;
        cursor: pointer;

      }
      .drop-down-list__item:not(:first-child){
          // border-top:1px solid red;
      }



      .seasons {
        display: flex;
        justify-content: flex-start;

  
      }

      .sold-products {
        .item-stats {
          display: flex;
          justify-content: space-between;
          margin: 25px 0 15px;

          .hyper-line {
            width: 0%;
            height: 4px;
            border-radius: 2px;
            background-color: $dark-blue;
          }

          .vuse-line {
            width: 0%;
            height: 4px;
            border-radius: 2px;
            background-color: $light-blue;
          }
        }

        .sold-items {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .item {
            display: flex;
            align-items: center;
            margin: 0 15px;
            font-weight: 700;

            &.item-vuse {
              color: $light-blue;

              .score {
                background-color: $light-blue;
              }
            }

            .score {
              width: 24px;
              height: 24px;
              margin-left: 8px;
              background-color: $dark-blue;
              text-align: center;
              line-height: 24px;
              color: #fff;
              font-size: 14px;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .ranks-pane {
      padding: 5px 0 10px 0;
      text-align: center;
      background-color: #f4f5f9;

      h4 {
        margin-bottom: .5em;
      }

      p {
        font-size: 12px;
        padding: 0 25px;
      }

      .disclaimer {
        color: #817e7e;
      }

      .ranks-holder {
        width: 100%;
        padding: 10px 0;
        overflow-x: scroll;

        .ranks {
          display: flex;
          justify-content: space-between;
          width: 800px;
          padding: 0 25px;

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 72px;
            background-color: #fff;
            color: $dark-blue;
            text-align: center;
            font-weight: 700;
            border-radius: 12px;
            border: solid 2px #efefef;

            &.completed {
              border: solid 2px $dark-blue;
            }

            &.current {
              border: solid 2px $dark-blue;
              background-color: $dark-blue;
              color: #fff;
            }

            p {
              margin: .3em 0 0;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .history-section {
    margin-top: 15px;
    background-color: #fff;
    padding: 15px 25px 60px;
    text-align: center;

    h3 {
      margin: .5em 0;
    }

    table {
      width: 100%;
      text-align: left;
      font-size: 12px;

      tr {
        width: 100%;

        td {
          padding: 10px 0;
          border-bottom: solid 1px #efefef;
        }

        .date {
          color: #817e7e;
          width: 60px;
        }

        .name {
          padding-left: 5%;
          width: calc(100% - 100px);
        }

        .score {
          width: 40px;
          color: $orange;
          font-size: 14px;
          font-weight: 700;
          text-align: right;
        }
      }
    }
  }
}