.payout {

  .history-section {
    margin-top: 15px;
    background-color: #fff;
    padding: 15px 25px 60px;
    text-align: center;

    h3 {
      margin: .5em 0;
      text-align: left;
    }

    table {
      width: 100%;
      text-align: left;

      tr {
        width: 100%;

        td {
          padding: 10px 0;
          border-bottom: solid 1px #efefef;
        }

        .date {
          width: 60px;
        }

        .name {
          padding-left: 5%;
          width: calc(100% - 100px);
        }

        .score {
          width: 40px;
          color: $orange;
          font-size: 14px;
          font-weight: 700;
          text-align: right;
        }
      }
    }
  }
}