.payout-pane {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
    background-image: linear-gradient(to bottom, var(--white), #fafcff);

    .ammount {
        text-align: center;
        width: 50%;
        padding: 0 10px 0 25px;

        .stats-link {
            margin-top: 0;
            display: block;
            line-height: 1.2;
        }
    }

    .payout-saldo {
        text-align: center;
        width: 50%;
        padding: 0 25px 0 10px;

        .payout-btn {
            margin-top: 20px;
        }
    }

    .desc {
        font-size: 12px;
    }

    .number {
        font-size: 64px;
        font-weight: 600;
        line-height: 1;
    }

    .currency {
        font-size: 12px;
        font-weight: 400;
    }

    .disclaimer {
        width: 100%;
        margin-top: 15px;
        padding-top: 15px;
        color: #817e7e;
        border-top: solid 1px #efefef;

        p {
            font-size: 12px;
            padding: 0 25px;
            margin: 0;
        }
    }

    .payout-columns {
        display: flex;

        .payout-column {
            width: 50%;
            margin-top: 7px;

            .number {
                font-size: 32px;
            }

            p {
                margin: 0;
                text-align: center;
                font-size: 12px;
                color: #817e7e;
            }
        }
        
    }

    .last-season-info {
        font-size: 12px;
        color: #817e7e;
        margin-bottom: 0;
        margin-top: .5em;

        span {
            font-size: 14px;
            font-weight: 700;
            color: $orange;
        }
    }
}