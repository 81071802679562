.bottom-nav {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 72px;
  z-index: 100;
  background-color: #fff;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;

    li {
      min-width: 33.33%;
      max-width: 33.33%;
      width: 33.33%;
      text-align: center;
      
      a, .bottom-nav-item {
        display: block;
        height: 100%;
        width: 100%;
        background-color: $dark-blue;
        color: #fff;
        text-decoration: none;
        font-size: 10px;
        font-weight: 700;
        padding-top: 10px;

        &.active {
          opacity: 1;
        }

        .icon {
          height: 24px;
          margin-bottom: 10px;
        }

        .new-sale-icon {
          margin: -46px auto 8px;
          width: 72px;
          height: 72px;
          border: 4px solid #dddee1;
          background-color: $orange;
          border-radius: 50%;
          position: relative;

          &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            top: 20px;
            left: 30px;
            width: 4px;
            height: 24px;
            border-radius: 2px;
            background-color: #fff;
          }

          &:after {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}