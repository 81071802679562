.rankings {

  .page-header {
    position: relative;
  }

  .season-header {
   
    display: flex;
    justify-content: space-between;
    h1 {
      font-weight: 600;
      padding: 0;
      margin: 0;
    }
    p {
      width: 70%;
    }

    
  }

  .season__wrapper {
    .season__title {
      font-weight: 600;
      text-align: right;
      margin: 5px 0;
    }
    
  }

  .ranks {
    padding-left: 0;
    padding-right: 0;
    display: none;

    &.active {
      display: block;
    }
  }

  .buttons-list {
    justify-content: flex-start;

    li {
      margin-right: 10px;
      padding: 4px 10px;
      width: auto;
      cursor: pointer;
    }
  }

  table {
    margin-top: 20px;
    margin-bottom: 60px;
    width: 100%;
    text-align: left;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      tr {
        td {
          background-color: #f4f5f9;

          &:first-child{
            width: 60px;
          }
    
          &:nth-child(2) {
            width: calc(100% - 100px);
          }
    
          &:last-child{
            width: 40px;
          }
        }
      }
    }

    tr {
      width: 100%;

      td {
        margin: 0;
        padding: 10px 0;
        border: 0;
        border-bottom: solid 2px #f4f5f9;
        background-color: #fff;

        &:first-child{
          padding-left: 25px;
        }

        &:nth-child(2) {
          padding-left: 5%;
        }

        &:last-child{
          padding-right: 25px;
        }
      }

      .nr {
        width: 60px;
        padding-left: 25px;
        font-weight: 700;
      }

      .name {
        padding-left: 5%;
        width: calc(100% - 100px);
        font-weight: 700;
      }

      .score {
        width: 40px;
        padding-right: 25px;
        color: $orange;
        font-size: 14px;
        font-weight: 700;
        text-align: right;
      }

      &.active {
        td {
          background-color: $orange;
          color: #fff;
        }
      }
    }
  }

  .seasons {
    li {
      padding: 2px 0;
      width: 70px;
      font-size: 12px;
      text-align: center;
      color: $orange;
      border-radius: 14.5px;
      border: solid 1px #ff8d03;
      transition: color .2s $QuadEaseOut, background .2s $QuadEaseOut;
      cursor: pointer;

      &.active {
        color: #fff;
        background-color: $orange;
      }
    }
  }
}