.regs {
  text-align: left;

  .page-header {
    display: flex;
    justify-content: space-between;

    h1 {
      color: #000;
      margin-top: .5em;
    }
  }

  .regs-list {
    li {
      margin: 25px 0 0;
      padding: 0 0 25px;
      border-bottom: solid 1px #ff8d03;
      color: #000;

      .reg-item-footer {
        display: flex;
        justify-content: space-between;
      }

      h3 {
        font-size: 14px;
      }

      &:last-child {
        border: 0;
      }
    }
  }
}