.hyper-vuse {
  overflow: hidden;
  padding-bottom: 120px;

  .slider-header {
    &__title,
    &__desc {
      text-align: center;
    }

    &__title {
      font-weight: 900;
      background-color: #063a88;
      font-size: 20px;
      color: #fff;
      padding: 0.3rem 0;
      margin: 0;
      font-weight: 600;

      &--gap {
        margin-top: 2rem;
      }
    }
    &__desc {
    }
  }

  // <div className="slider-header">
  //         <h4 className="slider-header__title">Velo mini</h4>
  //           <p className="slider-header__desc">Dla zaczynających przygodę z saszetkami nikotynowymi</p>
  //       </div>

  h2 {
    font-size: 32px;
    padding: 0 5%;

    &.small {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .indexed-nav {
    margin-bottom: 20px;
    li {
      width: 90px;
    }
  }

  .hyper-page,
  .vuse-page,
  .velo-page {
    display: none;

    &.active {
      display: block;
    }
  }

  .page-header {
    padding: 0 5%;
    text-align: center;

    h1 {
      text-align: center;
    }
  }

  .hyper-top-img {
    margin-top: 35px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .hyper-bot-img {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .hyper-slider-top {
    transform: translate(0, -40px);

    .slider-holder {
      margin: 0;
    }

    .slider {
      .cards {
        .card {
          padding: 0;
          display: block;
          min-height: 150px;
          min-height: 100px;
          overflow: hidden;

          .card-head {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f4f5f9;
            padding: 15px;

            .card-icon {
              width: 48px;
              height: 48px;

              img {
                width: 100%;
                height: auto;
              }
            }
          }

          .card-inner {
            padding: 15px 15px;
            height: auto;
            width: 100%;
            text-align: center;

            .small {
              display: block;
              margin-top: 0.5em;
              font-size: 10px;
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .hyper-slider-bot {
    transform: translate(0, -40px);

    .slider-holder {
      margin: 0;
    }

    .slider {
      .cards {
        .card {
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 100px;
          height: 140px;
          text-align: center;
          overflow: hidden;

          .card-inner {
            padding: 5px 15px;
            height: 100%;

            h3 {
              font-size: 14px;
              margin: 0.5em 0 0;
            }

            p {
              font-weight: 400;
            }

            .small {
              display: block;
              margin-top: 0.5em;
              font-size: 0.8em;
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .hyper-slider-flavors {
    .power-tobacco {
      display: flex;
      align-items: center;

      &__bullet {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-right: 4px;

        &--empty {
          border: 2px;
        }

        &--blue {
          background: #196bd0;
        }
        &--green {
          background: #7cc12d;
        }
        &--green-empty {
          border: 1px solid #7cc12d;
        }

        &--dark-green {
          background: #41bcac;
        }
        &--dark-green-empty {
          border: 1px solid #41bcac;
        }
        &--red {
          background: #e60a44;
        }
        &--red-empty {
          border: 1px solid #e60a44;
        }

        &--ice {
          background: #57b5b0;
        }
        &--dark-blue {
          background: #001e59;
        }

        &--dark-blue-empty {
          border: 1px solid #001e59;
        }
        &--yellow {
          background: #edb024;
        }
        &--yellow-empty {
          border: 1px solid #edb024;
        }
        &--brown {
          background: #461729;
        }
        &--brown-empty {
          border: 1px solid #461729;
        }
        &--dark-brown {
          background: #76392f;
        }
        &--dark-brown-empty {
          border: 1px solid #76392f;
        }
      }
    }
    .power-tobacco-wrapper {
      display: flex;
      margin-left: 10px;
    }

    .slider {
      .cards {
        .card {
          border-radius: 0;
          background-size: 121%;
          background-repeat: no-repeat;
          background-color: transparent;
          min-height: 200px;
          box-shadow: none;
          padding: 0;

          .card-head {
            padding: 12px 0 0 6%;
            color: #fff;
            font-weight: 700;
            height: 54vw;

            h3 {
              margin-bottom: 1.5em;
              font-size: 5vw;
            }

            p {
              font-size: 3.5vw;
              padding-right: 48%;
            }
          }

          .card-inner {
            height: auto;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 4px 0;

            img {
              width: 36px;
              height: 36px;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  .about-the-stick-img {
    width: 90%;
    margin: 30px auto;
    height: auto;
    display: block;
  }

  .about-the-stick-list {
    padding: 0 5%;
    margin-bottom: 40px;

    li {
      display: flex;
      margin-bottom: 15px;

      .nr {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        background-color: $blue;
        line-height: 24px;
        text-align: center;
        border-radius: 50%;
      }

      .content {
        width: calc(100% - 10px);

        h3 {
          margin-top: 0.3em;
          margin-bottom: 0.25em;
          font-size: 14px;
        }

        p {
          margin-top: 0.5em;
        }
      }
    }
  }

  .vuse-slider-top {
    transform: translate(0, -40px);

    .slider-holder {
      margin: 0;
    }

    .slider {
      .cards {
        .card {
          padding: 0;
          display: block;
          min-height: 100px;
          overflow: hidden;

          .card-head {
            background-color: #000;

            img {
              width: 100%;
              height: auto;
              display: block;
            }
          }

          .card-inner {
            padding: 5px 15px 10px;
            height: auto;
            text-align: center;

            h3 {
              font-size: 14px;
              margin-bottom: 0.5em;
            }

            p {
              margin-top: 0.5em;
            }

            .small {
              display: block;
              margin-top: 0.5em;
              font-size: 0.8em;
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .vuse-slider-mid {
    .slider-holder {
      margin: 30px 0 40px;
    }

    .slider {
      .cards {
        .card {
          padding: 0;
          display: block;
          min-height: 150px;
          overflow: hidden;
          background-color: transparent;
          border: solid 1px #dfdfdf;
          box-shadow: none;

          .card-head {
            padding: 20px 0 10px;

            img {
              display: block;
              width: 90%;
              height: auto;
              margin: 0 auto;
            }
          }

          .card-inner {
            padding: 5px 15px 10px;
            height: auto;
            text-align: center;

            h3 {
              font-size: 20px;
              margin-bottom: 0.5em;
              line-height: 1.2;
            }

            p {
              margin-top: 0.5em;
            }

            .small {
              display: block;
              margin-top: 0.5em;
              font-size: 0.6em;
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .vuse-slider-bot {
    transform: translate(0, -40px);

    .slider-holder {
      margin: 0;
    }

    .slider {
      .cards {
        .card {
          padding: 0;
          display: block;
          min-height: 150px;
          overflow: hidden;

          .card-head {
            img {
              margin: 0 auto -35px;
              width: 150px;
              height: auto;
              display: block;
            }
          }

          .card-inner {
            padding: 5px 15px 10px;
            height: auto;
            text-align: center;

            h3 {
              font-size: 20px;
              margin-bottom: 0.5em;
              color: $orange;
              line-height: 1.2;
            }

            p {
              margin-top: 0.5em;
            }

            .small {
              display: block;
              margin-top: 0.5em;
              font-size: 0.6em;
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .velo-top-img {
    position: relative;
    margin-top: 15px;

    img {
      width: 100%;
      height: auto;
    }

    &__content {
      position: absolute;
      width: 100%;
      top: 30vw;
      color: #fff;
      text-align: center;
    }

    &__header {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 5px;
    }

    &__desc {
      font-size: 14px;
    }
  }

  .velo-slider-direction {
    margin-top: 1rem;

    @include vHD {
      margin-top: 5vw;
    }
    @include HD {
      margin-top: 7vw;
    }

    &__footer {
      text-align: center;
      color: #001854;
      font-weight: 700;
      text-decoration: underline;
      position: relative;
      width: 170px;
      margin: 0 auto;
      display: block;

      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 12px;
        width: 16px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-image: url(../../img/arrow.svg);
      }
    }
  }

  .velo-slider-top {
    transform: translate(0, -16vw);

    .slider-holder {
      margin: 0;
    }

    .slider {
      .cards {
        width: 400vw;

        .card {
          padding: 0;
          display: block;
          min-height: 100px;
          overflow: hidden;
          background-color: transparent;
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06),
            0 -8px 18px 0 rgba(0, 0, 0, 0.16);

          .card-head {
            background-color: #063a88;
            padding: 24px 0;

            img {
              width: 64px;
              height: auto;
              margin: 0 auto;
              display: block;
            }
          }

          .card-inner {
            padding: 5px 15px 10px;
            height: auto;
            text-align: center;
            background-color: #fff;

            h3 {
              font-size: 14px;
              margin-bottom: 0.5em;
            }

            p {
              margin-top: 0.5em;
            }

            .small {
              display: block;
              margin-top: 0.5em;
              font-size: 0.8em;
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .velo-slider-mid {
    .slider-holder {
      position: relative;
      padding-bottom: 30px;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0px;
      //   left: 50%;
      //   width: 10px;
      //   height: 10px;
      //   border-top: 3px solid $blue;
      //   border-right: 3px solid $blue;
      //   transform: rotate(45deg);
      // }

      // &:before {
      //   content: "";
      //   position: absolute;
      //   bottom: 5px;
      //   left: 50%;
      //   width: 21px;
      //   height: 3px;
      //   margin-left: -9px;
      //   background-color: $blue;
      // }
    }

    .card-inner {
      .polar-mint {
        .color-min {
          color: #009180;
        }
      }

      .berry-frost {
        .color-min {
          color: #3a3aa8;
        }

        .circle-full {
          border: 1px solid #3a3aa8;
          background-color: #3a3aa8;
        }

        .circle-empty {
          border: 1px solid #3a3aa8;
        }
      }

      .red {
        .color-min {
          color: #ff0000;
        }

        .circle-full {
          border: 1px solid #ff0000;
          background-color: #ff0000;
        }

        .circle-empty {
          border: 1px solid #ff0000;
        }
      }

      .circle-full {
        display: inline-block;
        margin: 0 2px;
        border: 1px solid #009180;
        width: 8px;
        height: 8px;
        background-color: #009180;
        border-radius: 50%;
      }

      .circle-empty {
        margin: 0 2px;
        display: inline-block;
        border: 1px solid #009180;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }

  .velo-slider-bot {
    .velo-slider-direction__footer {
      transform: translateY(2vw);
    }

    .slider-holder {
      position: relative;
      margin: 25px 0 0;
      padding: 30px 0 35px;
      background-image: url(../../img/hyper-vuse/velo/bg@3x.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top;
      background-position: left;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   bottom: 15px;
      //   left: 50%;
      //   width: 10px;
      //   height: 10px;
      //   border-top: 3px solid $blue;
      //   border-right: 3px solid $blue;
      //   transform: rotate(45deg);
      // }

      // &:before {
      //   content: "";
      //   position: absolute;
      //   bottom: 20px;
      //   left: 50%;
      //   width: 21px;
      //   height: 3px;
      //   margin-left: -9px;
      //   background-color: $blue;
      // }
    }

    .slider {
      .cards {
        .card {
          padding: 0;
          display: block;
          min-height: 150px;
          overflow: hidden;
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06),
            0 -8px 18px 0 rgba(0, 0, 0, 0.16);

          .card-head {
            img {
              display: block;
              margin: 0 auto;
            }
          }

          .card-inner {
            padding: 5px 15px 10px;
            height: auto;
            text-align: center;

            h3 {
              font-size: 20px;
              margin: 0.5em 0;
              color: $orange;
              line-height: 1.2;
            }

            p {
              margin-top: 0.5em;
            }

            .small {
              display: block;
              margin-top: 0.5em;
              font-size: 0.6em;
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .page-footer {
    padding: 25px 5%;

    h2 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 1em;
    }
  }

  video {
    margin-bottom: 40px;
    width: 100%;
    height: auto;
  }
}
