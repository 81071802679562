// ------------------------------------------------
// Site Name:
// Author:
// ------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
}

body {
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
  color: #000;
  line-height: 1.3;
  background-color: #f4f5f9;
}

section {
  padding: 0 25px 0;
}

.app {
  position: relative;
}

.page {
  padding: 65px 0 70px;
}

.title {

}

.page-header {

  h1 {
    margin-bottom: .5em;
    text-align: left;
  }
}

.bat-logo {
  width: 60px;
  position: absolute;
  top: 38px;
  left: 35px;
}

.color-line {
  width: 100%;
  height: 32px;
  border: 0;
  display: block;
}

.offline-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background-color: #f44;
  text-align: center;
  z-index: 999999;

  p {
    color: #fff;
    font-size: 10px;
    padding: 3px;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

h1, h2, input {
  margin: 0;
}

h2, h1 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
  margin: 1em 0 1em;
}

h1, h2 {
  margin-bottom: .8em;
}

p, a, input {
  font-size: 14px;
  text-decoration: none;
}

a {
  font-weight: 700;;
  color: #001854;
  text-decoration: underline;
}

.color-orange {
  color: $orange;
}

.color-blue {
  color: $blue;
}

.bold {
  font-weight: 700;
}

.big {
  font-size: 1.5em;
}

.underline-link {
  color: $dark-blue;
  text-decoration: underline;
  font-weight: 600;
}

.input-item {
  margin-bottom: 20px;
  text-align: left;

  label {
    display: block;
    font-weight: 700;
    margin-bottom: .5em;
  }

  p {
    margin: 0 0 .7em !important;
    font-size: .9em;
  }
}

input, select {
  border-radius: 3px;
  border: solid 1px #000;
  padding: 13px 15px;
  outline: none;
  margin-bottom: 16px;
  display: block;
  width: 100%;
  background-color: #f4f5f9;

  &::placeholder {
    color: #afafaf;
    font-style: italic;
  }
}

select {
  padding: 9px 15px;
}

.checkbox-item {

  &.indent {
    width: calc(100% - 40px);
    margin-left: 40px;
  }

  label {
    position: relative;
    padding-left: 40px;
    display: block;
    margin: 0px 0 20px;
    line-height: 1.2;
    font-size: .8em;
    text-align: left;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      //top: 5px;
      width: 20px;
      height: 20px;
      border: solid 1px #000;
      border-radius: 3px;
    }

    a {
      font-size: 1em;
    }
  }
  
  input[type="checkbox"]:checked + label:before {
    background-color: #fbba00;
  }
  
  input {
    display: none;
  }
}

.btn {
  border-radius: 4px;
  background-color: $dark-blue;
  font-weight: 700;
  border: none;
  color: #fff;
  padding: 10px 15px;
  text-align: center;
  display: block;
  width: 100%;
  height: 40px;
  line-height: 20px;
  outline: none;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  border-radius: 20px;

  &:active {
    transform: scale(0.96);
  }

  &.btn-white,&.white-btn {
    background: #fff;
    color: $dark-blue;
  }

  &.orange-btn {
    background-image: linear-gradient(to bottom, #ffb80d, #da9e0d);
  }

  &.empty-btn {
    background-color: transparent;
    background-image: none;
    border: 2px solid $blue;
    color: $blue; 
  }

  &.redeem-btn {
    width: 70px;
    font-size: 12px;
    padding: 10px;
  }

  &.inactive {
    background-color: #817e7e;
  }

  &.disabled-btn {
    background: #817e7e;
    pointer-events: none;
  }

}

div.center {
  text-align: center;
}

.error {
  padding: 18px 12px;
  border-radius: 12px;
  background-color: #f44;
  color: #fff;
  font-size: 12px;
}

.dark-pane {
  margin-top: 30px;
  padding: 15px 20px;
  background-color: $dark-blue;
  border-radius: 12px;
  color: #fff;
  text-align: left;
}

.buttons-list {
  display: flex;
  justify-content: space-between;

  li {
    padding: 4px 0;
    width: 55px;
    font-size: 12px;
    text-align: center;
    color: $orange;
    border-radius: 14.5px;
    border: solid 1px #ff8d03;
    transition: color .2s $QuadEaseOut, background .2s $QuadEaseOut;

    &.active {
      color: #fff;
      background-color: $orange;
    }
  }
}

.welcome {
  text-align: center;
  font-size: 12px;
  color: #817e7e;

  .profile-name {
    color: #000;
  }

  .bat-number {
    color: #001854;
  }

  span {
      font-size: 20px;
      color: #000;
      font-weight: 700;
  }
}

.icon {
  &.centered {
    display: block;
    margin: 40px auto 20px;
  }
}

.orange {
  color: $orange;
}

.indexed-nav {
  display: flex;
  justify-content: center;

  li {
    padding: 4px 0;
    margin: 0 5px;
    width: 55px;
    font-size: 12px;
    text-align: center;
    color: $orange;
    border-radius: 14.5px;
    border: solid 1px #ff8d03;
    transition: color .2s $QuadEaseOut, background .2s $QuadEaseOut;
    cursor: pointer;

    &.active {
      color: #fff;
      background-color: $orange;
    }
  }
}

////////////////////////////////////////////////////////////////////////////

.slider-holder {
    position: relative;
    z-index: 10;
    width: 100%;
    margin-top: 25px;

  .slider {

    &.transition {
      transition: transform .5s $CubicEaseOut;
        }
        
        ul {
            display: flex;

            li {
                width: 100vw;
            }
        }
  }

  .cards {

    li {

    }

    .card {
      position: relative;
      width: 80%;
      min-height: 300px;
      margin: 0 auto;
      padding: 25px 25px 30px;
      font-size: 16px;
      line-height: 1.5;
      border-radius: 12px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
      background-color: #ffffff;

      .nr {
        position: relative;

        span {
          position: absolute;
          left: 50%;
          top: 0;
          margin-lefT: -21px;
          width: 42px;
          height: 42px;
          color: #001854;
          font-size: 20px;
          font-weight: 700;
          line-height: 42px;
          text-align: center;
        }
      }

      .card-inner {
        display: flex;
        flex-direction: column;
        height: 150px;
        justify-content: center;

        p {
          margin: .5em 0;
        }
      }
    }
  }

  .dot-nav {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 15px 15px;
    margin-bottom: 0;

    li {
      position: relative;
      height: 20px;
      width: 20px;
      margin: 0;

      &.active {
        &:before {
                    background-color: #f58958;
                    transform: scale(1.3);
        }
      }
      
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 8px;
                height: 8px;
                margin: -4px 0 0 -4px;
        background-color: #c4c4c4;
        transform: scale(1);
        border-radius: 50%;
      }
    }
  }

  .slider-counter {
    position: absolute;
    bottom: 20px;
    left: 50%;
    padding: 6px 15px;
    font-size: 13px;
    color: #fff;
    border-radius: 20px;
    background-color: rgba(0,0,0,0.6);
    transform: translate(-50%,0);

  }
}