.register-page, .login-page, .remind-page {
	text-align: center;
	height: 100vh;
	min-height: 400px;

	.page-inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: 0 auto;
		width: 100%;
		max-width: 320px;
	}

	p {
		margin-bottom: 25px;
	}

	.remind {
		font-size: 12px;

		a {
			color: #414141;
			text-decoration: underline;
			display: inline-block;
			margin: 8px 0 22px;
			font-size: 12px;
		}
	}

	.login-link {
		font-size: 12px;
		margin: 22px 0 22px;

		a {
			color: #414141;
			text-decoration: underline;
			display: inline-block;
			font-size: 12px;
		}
	}

	label {
		display: inline-block;
		margin-bottom: 8px;
	}

	.input-pin {
		position: relative;
		margin-bottom: 16px;
		height: 45px;
	}

	.pin {
		max-width: 200px;
		margin: 0 auto;
		display: flex;
		flex-flow: row;
		flex-wrap: nowrap;
		justify-content: space-between;

		input {
			position: relative;
			min-width: 36px;
			max-width: 36px;
			width: 36px;
			height: 45px;
			margin-bottom: 0;

			&.mask + label {
				position: absolute;
				left: 5px;
				top: 5px;
				width: calc( 100% - 10px );
				height: calc( 100% - 10px );
				background-color: #fff;

				&:after {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 8px;
					height: 8px;
					background-color: #000;
					border-radius: 50%;
				}
			}
		}
	}
}

.register-page {
	height: auto;

	.register-page-head {
		position: relative;

		.back-icon {
			position: absolute;
			top: 16px;
			left: 0;
			width: 32px;
			height: 32px;
		}

		.register-page-nav {
			position: absolute;
			left: 50%;
			top: 28px;
			margin-left: -76px;
			display: flex;
			justify-content: center;
			width: 152px;
			height: 4px;

			li {
				display: block;
				width: 40px;
				height: 100%;
				background-color: #ffdaae;
				border-radius: 2px;
				margin: 0 5px;

				&.active {
					background-color: $orange;
				}
			}
		}
	}

	.page-inner {
		top: 90px;
		transform: translate(-50%, 0);

		.small {
			text-align: left;
			font-size: .8em;

			a {
				font-size: 1em;
			}
		}

		.btn {
			margin-top: 20px;
			margin-bottom: 30px;
		}
	}
}

.form-step {
	display: none;

	&.active {
		display: block;
	}
}