.notifications {
  .notifications-list {
    margin: 1em 0;

    .notification-item {
      display: block;
      margin-bottom: 1em;

      &.new a {
        background-color: #feb70c;
      }

      a {
        display: block;
        padding: 15px;
        text-align: center;
        background-color: #fff;
        border-radius: 12px;
        text-decoration: none;
        
        h3 {
          margin-top: 0;
          margin-bottom: .5em;
          font-size: 14px;
          color: #001854;
          font-weight: 700;
          text-decoration: underline;
        }

        p {
          margin-top: .5em;
          margin-bottom: 0;
          font-size: 12px;
          color: #000;
          font-weight: 400;
        }
      }
    }
  }
}

.notification {
  padding-bottom: 120px;
}