.shortcut {
  text-align: center;
  overflow: hidden;

  .page-header {
    padding-left: 10%;
    padding-right: 10%;
  }

  h1 {
    text-align: center;
  }
}