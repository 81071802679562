.dashboard {
    position: relative;

    .stats-pane {
        margin: 15px 0;
        padding: 20px 25px;
        background-color: #fff;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
        background-image: linear-gradient(to bottom, var(--white), #fafcff);

        .stats-progress {
            position: relative;
            width: 248px;
            margin: 25px auto 35px;

            .sales-info {
                position: absolute;
                width: 100%;
                top: 48px;
                text-align: center;

                .sales-no {
                    font-size: 40px;
                    font-weight: 600;
                }

                .sales-name {
                    margin: 0 0 1em;
                }

                .sales-description {
                    font-size: 12px;
                    color: #817e7e;
                }
            }

            .level {
                position: absolute;
                left: -20px;
                bottom: -30px;
                padding: 0 12px;
                line-height: 22px;
                font-size: 12px;
                border-radius: 12px;
                border: solid 1px #efefef;
                color: #817e7e;
                background-color: #fff;

                &:after {
                    content: "";
                    position: absolute;
                    top: -25px;
                    left: 50%;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    margin-left: -8px;
                    background-color: #efefef;
                }

                &.active {
                    border: solid 1px $orange;
                    color: #000;

                    &:after {
                        background-color: $orange;
                    }
                }

                &.level-novice {
                    &:after {
                        top: -23px;
                        left: 35px;
                    }
                }

                &.level-beginner {
                    bottom: auto;
                    left: -41px;
                    top: 124px;
                }

                &.level-pro {
                    bottom: auto;
                    left: -35px;
                    top: 59px;

                    &:after {
                        left: 70%;
                    }
                }

                &.level-top {
                    bottom: auto;
                    left: 141px;
                    top: -8px;

                    &:after {
                        top: 3px;
                        left: -18px;
                    }
                }

                &.level-ultimate {
                    left: auto;
                    right: -13px;
                    top: 77px;
                    bottom: auto;
                }

                &.level-hero {
                    left: auto;
                    right: -10px;

                }
            }
        }

        .progress-bar {
            position: relative;

            .fill {
                position: absolute;
                top: 0;
                left: 0;
                transform: rotateY(180deg);
            }
        }
    }

    .welcome-message {
        z-index: 910;
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 32px;
        right: 0;
        height: calc(100% - 32px);
        width: 100%;
        padding: 25px 25px 25px 25px;
        color: #fff;
        text-align: center;
        background-color: $dark-blue;
        transform: translate(105%, 0);
        transition: transform, .7s $CubicEaseInOut;

        &.active {
            transform: translate(0,0);
        }

        .close {
            position: absolute;
            top: 16px;
            left: 25px;
            cursor: pointer;
        }

        h2 {
            font-size: 20px;
            margin-bottom: 1em;
        }

        p {
            margin: 1em 0;
        }

        .welcome {
            color: $orange;
    
            span {
                color: #fff;
            }
        }

        .content {
            height: 60vh;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .message-screen-icon {
                margin: 1em 0;
                text-align: center;
            }
        }
    }

    .season-info {
        position: absolute;
        top: 15px;
        left: 25px;
        line-height: 1.2;
        font-size: .9em;
    }

    .payout-pane {
        padding: 0;
        overflow: hidden;

        .ammount {
            padding-top: 15px;
            padding-bottom: 15px;
            width: 75%;
        }

        .payout-saldo {
            padding: 0;
            width: 25%;
            
            .payout-btn-full {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                color: #fff;
                text-decoration: none;
                background-color: $dark-blue;
            }
        }
    }
}