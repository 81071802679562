header {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	z-index: 900;
	width: 100%;

	.bell-icon {
		position: absolute;
		top: 48px;
		right: 80px;
		width: 32px;
		height: 32px;

		.unseen {
			position: absolute;
			bottom: -2px;
			right: -2px;
			width: 12px;
			height: 12px;
			color: #fff;
			text-align: center;
			font-size: 9px;
			line-height: 12px;
			text-decoration: none;
			border-radius: 50%;
			background-color: $orange;
		}
	}

	.back-icon {
		position: absolute;
		top: 48px;
		left: 25px;
		width: 32px;
		height: 32px;
	}
}
