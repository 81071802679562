.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModalPortal > * {
    opacity: 0;
}

.overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: rgba(0,0,0,.15);

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }
}

.modal {
    position: absolute;
    left: 50%;
    top: 10vh;
    transform: translate(-50%, 0);
    transition: transform 300ms $CubicEaseInOut .2s, opacity 300ms $CubicEaseInOut;
    background: transparent;
    max-width: 100%;
    width: calc(100% - 70px);
    height: 85vh;
    padding: 30px;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 40px;

    .popup-inner {
        width: 100%;
        text-align: center;

        p {
            margin-bottom: 14px;

            &.gray {
                color: #414141;
            }
        }

        .btn {
            max-width: 255px;
            margin: 0 auto;
        }

        .buttons-payment {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 30px;

            .btn {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }

        &.printer {
            .center {
                .btn {
                    margin-bottom: 15px;
                }
            }

            .disabled {
                filter: grayscale(80%) brightness(2);
            }

            .badge {
                &.loading {
                    .circle {
                        animation: spin .7s $CubicEaseInOut infinite;

                        &:after {
                            animation: spin2 .7s $CubicEaseInOut infinite;
                        }
                    }

                    .status {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        &:before {
                            content: ". . .";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 100;
                            color: #fff;
                            font-weight: 700;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .close-modal {
        position: absolute;
        top: -19px;
        right: 19px;
        width: 38px;
        height: 38px;
    }
}

.bottom-overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: rgba(0,0,0,0.3);

    &_after-open {
        opacity: 1;

        .bottom-modal {
            transform: translateY(0);
        }
    }
    &_before-close {
        opacity: 0;

        .bottom-modal {
            transform: translateY(100%);
        }
    }

    .swipe-close {
        width: 100%;
        height: 100%;
    }
}

.bottom-modal {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 0 -10px 50px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 10;
    transform: translateY(100%);
    transition: transform .4s $QuadEaseInOut;
    padding: 20px 25px;
    text-align: center;
    outline: none;

    .btn-close {
        z-index: 20;
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        display: block;
        text-align: center;
        outline: none;

        .icon {
            position: relative;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-image: linear-gradient(to bottom, #0047af, #002277);
            margin: 0 auto;

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 14px;
                height: 1px;
                background-color: #fff;
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 14px;
                height: 1px;
                background-color: #fff;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        p {
            text-transform: uppercase;
            margin-top: 10px;
            font-size: 10px;
        }
    }

    .popup-inner {
        width: 100%;
        height: calc( 100vh - 100px );

        h2 {
            margin-top: 0;
            font-weight: 700;
            text-align: left;
            padding-bottom: 12px;
            border-bottom: solid 1px #efefef;
        }

        &.error {
            h2, p {
                color: #d80000;
            }
        }

        &.cancel-transaction {
            .buttons-payment {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-top: 30px;
                padding: 0 20px;

                .btn {
                    min-width: 45%;
                    max-width: 45%;
                    width: 45%;
                }
            }
        }

        .activities-list {

            li {
                position: relative;
              display: flex;
              justify-content: space-between;
              text-align: left;
              padding-left: 30px;
              margin: 25px 0;
        
              &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 16px;
                height: 16px;
                border: 1px solid $dark-blue;
                border-radius: 50%;
              }

              &.active {
                  &:after {
                      background-color: $dark-blue;
                  }
              }
        
              a {

              }

              .score {
                  color: $orange;
                  font-weight: 700;
              }
            }
        }

        &.popup-code {
            .dark-pane {
                height: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .bat-code {
                    text-align: center;
                    font-size: 42px;
                    letter-spacing: .3em;
                    font-weight: 700;
                }
            }
        }

        &.popup-info {
            h2 {
                margin: .8em 0;
                font-size: 2em;
                color: $blue;
                text-align: center;
                font-weight: 600;
                border :0;
            }
            &--quiz {
                padding-top: 102px;
                position: relative;
                .btn {
                    position: absolute;
                    bottom: 10px;
                }
                h2 {
                    margin: .8em auto;
                    color: $dark-blue;
                    font-size: 32px;
                    line-height: 32px;
                }

                p {
                    padding: 0 5%;
                }  
                .color-orange {
                    color: $orange;
                    font-size: 20px;
                    font-weight: 600;
                }
            }

        }
     
        &.popup-error {
            text-align: center;
            padding-top: 30px;
            position: relative;
            
            h2 {
                margin: .5em 0;
                font-size: 32px;
                color: #ff4444;
                border: 0;
                text-align: center;
            }

            .color-red {
                color: #ff4444;
                font-weight: 700;
            }

            .btn {
                position: absolute;
                bottom: 10px;
            }

            p {
                padding: 0 5%;
            }            
        }
    }

    .popup-new-sale {
        height: 200px;

        h3 {
            margin-top: 2em;
        }
        p {
            margin: 2em 0;
        }
    }
}

.popup-overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: transparent;

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }
}

.popup-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 300ms $CubicEaseInOut .2s, opacity 300ms $CubicEaseInOut;
    background: transparent;
    max-width: 280px;
    width: 100%;
    padding: 30px;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.17);

    .popup-inner {
        width: 100%;
        text-align: center;

        h3 {
            margin-top: 0;
            margin-bottom: .5em;
            font-size: 1.2em;
        }

        .buttons {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 30px;

            .btn {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }

        .hours-inputs {
            display: flex;
            align-items: center;

            input {
                margin: 10px 10px 20px;
            }
        }
    }
}

.action-overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: rgba(0,0,0,.15);

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }
}