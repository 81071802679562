.profile {
  padding-bottom: 0;

  .login-section {
    margin: 20px 0;
    padding: 20px 25px;
    background-color: #fff;

    h4 {
      margin: 0 0 1em;
    }

    .password-item {
      label, .label {
        display: block;
        font-weight: 700;
        margin-bottom: .5em;
      }

      p {
        font-size: 12px;
      }
    }
  }

  input {
    background-image: url(../../img/icon-edit-primary.svg);
    background-repeat: no-repeat;
    background-position: 97% 10px;

    &#email, &#mobilePhone, &#dob, &#firstName, &#lastName, &#salesPoint {
      background-image: none;
    }

    &#email, &#mobilePhone, &#firstName, &#lastName, &#salesPoint {
      background-color: #e7e8ec;
    }
  }

  .checkbox-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;

    .label-text {
      font-size: 0.9em;
    }

    .check {
      position: relative;
      color: #fff;
      text-align: right;
      padding: 2px 8px 2px 8px;
      height: 22px;
      width: 52px;
      border-radius: 15px;
      background-color: #001854;

      &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #fff;
      }

      &.active {
        text-align: left;

        &:after {
          left: auto;
          right: 2px;
        }
      }
    }
  }

  .residency-section {
    padding-top: 20px;
    padding-bottom: 10px;
    background-color: #fff;
  }

  .save-section {
    //background-color: #fff;
    padding-bottom: 140px;
    padding-top: 10px;
  }

  .btn {
    margin-top: 30px;
  }
}